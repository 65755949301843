'use client'
import { Button } from '@components/ui/button'
import { cn } from '@lib/utils'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import React, { FC } from 'react'

type NavbarProfileUserNullProps = React.HTMLAttributes<HTMLDivElement>

const NavbarProfileUserSection: FC<NavbarProfileUserNullProps> = ({ ...props }) => {
  const { data: session, status } = useSession()
  const router = useRouter()

  const handleLoginBtn = () => {
    router.push('/signin')
  }

  return (
    <div className={cn('', props.className)}>
      {session == null ? (
        <div className=" w-full flex justify-between items-center md:block">
          <div className=" pb-2 hidden md:flex">
            <div className=" font-bold text-neutral-800 texxt-[4rem]">Welcome</div>
            <div className=" md:hidden body_small text-neutral-600">To access account and manage orders</div>
          </div>
          <div className=" flex md:hidden mobile_thin_medium py-4 text-neutral-700 w-1/2">
          To access account and manage orders
          </div>
          <Button
            variant="outline"
            className="my-2 text-primary-500 mobile_thin_medium md:label_medium hover:text-primary-500 hover:bg-primary-100"
            onClick={handleLoginBtn}
          >
            LOGIN<div className=" flex md:hidden">&nbsp;/&nbsp;SIGNUP</div>
          </Button>
        </div>
      ) : (
        <div>
          <div className=" heading_small text-neutral-800 text-[4rem]">
              Hello {session.user.firstName} {window.location.host === 'localhost:4000' || window.location.host === 'localhost:3000' ? session.user.userId : null}
          </div>
          <div className=" body_small text-neutral-800 mb-2">{session.user.phoneNumber}</div>
        </div>
      )}
    </div>
  )
}

export default NavbarProfileUserSection
