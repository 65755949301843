'use client'
import Logo from '@assets/eventshoppeLogo.svg'
import Image from 'next/image'
import React, { useCallback } from 'react'

import { cn } from '@/lib/utils'
import BackIcon from '@assets/backArrow.svg'
import Link from 'next/link'

import NavMenu from '@components/atoms/NavMenu'
import ShareButton from '@components/atoms/ShareBtn'
import { Share2 } from 'lucide-react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'
import useCartStore from '@store/CartStore'

export interface NavbarProps extends React.HtmlHTMLAttributes<HTMLDivElement> {}

const Navbar: React.FC<NavbarProps> = ({ className, ...props }) => {
  const [search, setSearch] = React.useState('')
  const pathName = usePathname()
  const [suggestions, setSuggestions] = React.useState<string[]>([
    'Red Velvet Cake',
    'Blueberry Cheesecake'
  ])

  const router = useRouter()
  const cartContext = useCartStore()
  const searchParams = useSearchParams()
  const productName = searchParams.get('prod')
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearch(value)
  }

  // useEffect(() => {
  //   if (session && cartContext) {
  //     synCart(session?.user.userId, cartContext)
  //   }
  // }, [session])

  const handleClickBack = useCallback(() => {
    const split = pathName.split('/')
    const occasion = searchParams.get('occasion')
    const category = searchParams.get('category')
    if (split[split.length - 1].startsWith('PRD')) {
      if (occasion && category) router.push(`/products/${occasion}/${category}`)
      else {
        router.push('/')
      }
      return
    }
    router.push('/')
  }, [pathName, router, searchParams])

  const isProductPage = useCallback(() => {
    if (pathName) {
      if (pathName.includes('product') && pathName.toLowerCase().includes('prd')) {
        return true
      }
    }
    return false
  }, [pathName])

  const shareMessage = 'hey checkout this product I saw at eventshoppe'
  const shareMessageTitle = productName ? productName : 'Eventshoppe'

  return (
    <div
      className={cn(
        'fixed z-[100] w-[100vw] min-h-[40px] md:min-h-[70px] bg-white items-center flex justify-between px-4 md:px-36 md:[justify-around]  drop-shadow-sm'
      )}
      {...props}
    >
      <div className=" flex md:gap-24 items-center">
        <div
          className={`flex md:hidden mr-4 ${pathName === '/' ? 'hidden' : ''}`}
          onClick={handleClickBack}
        >
          <Image src={BackIcon} alt="Go Back" width={30} height={25} />
        </div>
        <Link
          href="/"
          className="flex items-center justify-start md:[justify-center] cursor-pointer lg:h-full"
        >
          <Image
            src={Logo}
            alt="Event Shoppe Logo, click to go home screen"
            className="flex-1 w-44"
          />
        </Link>
        {/* <Input
          // placeHolderImg={SearchIcon}
          placeholder="Search for venue, catering, decor and more"
          className=" flex-1 hidden md:flex md:w-96 border border-neutral-500 rounded-md h-10 px-3 pl-10 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium  focus-visible:outline-none  focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
          onChange={handleSearch}
        /> */}
      </div>
      <div className=" pr-2 md:pr-0 flex items-center gap-2 justify-center">
        <div className="block md:hidden">
          {isProductPage() ? (
            <ShareButton
              text={shareMessage}
              title={shareMessageTitle}
              url={pathName}
              variant={'link'}
              className="text-black p-0 items-center"
            >
              <Share2 strokeWidth={1} />
            </ShareButton>
          ) : null}
        </div>
        <NavMenu />
      </div>
    </div>
  )
}

export default Navbar
