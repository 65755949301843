'use client'
import homeIcon from '@assets/homeIcon.svg'
import CartIcon from '@assets/navIcons/cart.svg'
import ProfileIcon from '@assets/profileIcon.svg'
import { userContext } from '@components/context/userContext'
import { Button } from '@components/ui/button'
import { NavigationMenu, NavigationMenuItem } from '@components/ui/navigation-menu'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger
} from '@components/ui/sheet'
import useCartStore from '@store/CartStore'
import { PhoneCallIcon } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { FC, useContext, useState } from 'react'

const inclusion: string[] = ['/', '/wishlist']

const NavDock: FC = () => {
  const { data: session } = useSession()
  const router = useRouter()
  const path = usePathname()
  const cartContext = useCartStore()
  const user = useContext(userContext)
  const handleSignout = () => {
    // TODO: check here
    if (user?.setAddresses) {
      // console.log('clearing')
      user.setAddresses({ method: 'clear' })
    }
    cartContext.updateCart({ method: 'clear' })
    signOut()
  }
  const [isSheetOpen, setIsSheetOpen] = useState(false)

  const handleNavigation = (href: string) => {
    setIsSheetOpen(false)
    router.push(href)
  }

  if (!inclusion.includes(path)) {
    if (isSheetOpen) setIsSheetOpen(false)
    return null
  }

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <div className=" bg-white fixed bottom-0 h-[10vh] shadow upward-shadow w-full px-[5vw] z-[1000]">
        <NavigationMenu className="list-none z-50 flex gap-[2.5vw] text-neutral-900 w-[100vw] justify-between h-full">
          <NavigationMenuItem className=" flex-1 w-[20vw]">
            <Link href={'/'}>
              <div className=" flex flex-col items-center gap-1">
                <Image
                  src={homeIcon}
                  alt="access your wishlist"
                  height={25}
                  style={
                    path === '/'
                      ? {
                          filter:
                            'invert(20%) sepia(50%) saturate(5000%) hue-rotate(300deg) brightness(90%) contrast(90%)'
                        }
                      : {}
                  }
                />
                <div className={`${path === '/' ? 'text-primary-500' : ''} mobile_thin_small`}>
                  Home
                </div>
              </div>
            </Link>
          </NavigationMenuItem>

          <SheetTrigger asChild>
            <div
              className="flex flex-col items-center gap-1 w-[20vw]"
              onClick={() => {
                if (session) {
                  // console.log('signed in')
                } else {
                  router.push('/signin')
                }
              }}
            >
              <Image src={ProfileIcon} alt="profile" height={25} />
              <div className={`${path === '/profile' ? 'text-primary-500' : ''} mobile_thin_small`}>
                Account
              </div>
            </div>
          </SheetTrigger>

          {/* <NavigationMenuItem className=" flex-1 w-[20vw]">
            <Link href={'/wishlist'}>
              <div className=" flex flex-col items-center gap-1">
                <div className="">
                  <Image
                    src={WishlistIcon}
                    alt="wishlist"
                    height={25}
                    style={
                      path === '/wishlist'
                        ? {
                            filter:
                              'invert(20%) sepia(50%) saturate(5000%) hue-rotate(300deg) brightness(90%) contrast(90%)'
                          }
                        : {}
                    }
                  />
                  {session && wishlist?.length !== 0 ? (
                    <div className="absolute text-white top-3 ml-4 bg-primary-400 w-[20px] flex justify-center items-center opacity-90 rounded-full h-[20px] text-sm aspect-square ">
                      {wishlistStatus === 'LOADING' ? (
                        <span className="animate-spin">
                          <Loader2 />
                        </span>
                      ) : (
                        wishlist?.length
                      )}
                    </div>
                  ) : null}
                </div>

                <div
                  className={`${path === '/wishlist' ? 'text-primary-500' : ''} mobile_thin_small`}
                >
                  Wishlist
                </div>
              </div>
            </Link>
          </NavigationMenuItem> */}

          <NavigationMenuItem className=" flex-1 w-[20vw]">
            <Link href={'/shopping-cart/cart'}>
              <div className="flex relative flex-col items-center gap-1  ">
                <Image
                  src={CartIcon}
                  alt="cart"
                  height={25}
                  style={
                    path === '/shopping-cart/cart'
                      ? {
                          filter:
                            'invert(20%) sepia(50%) saturate(5000%) hue-rotate(300deg) brightness(90%) contrast(90%)'
                        }
                      : {}
                  }
                />
                <div
                  className={`${
                    path === '/shopping-cart/cart' ? 'text-primary-500' : ''
                  } mobile_thin_small`}
                >
                  Cart
                </div>
                {cartContext?.cartItems.length !== 0 && (
                  // <div className="absolute top-0  text-white bg-primary-400 w-[20px] flex justify-center items-center opacity-90 rounded-full h-[20px] text-sm aspect-square ">
                  <div className="absolute -top-3 right-6 translate-x-2.5 text-white bg-primary-400 w-[20px] flex justify-center items-center opacity-90 rounded-full h-[20px] text-sm aspect-square">
                    {cartContext?.cartItems.length}
                  </div>
                )}
              </div>
            </Link>
          </NavigationMenuItem>
          <NavigationMenuItem className=" flex-1 w-[20vw]">
            <a href="tel:+918123400066">
              <div className=" flex flex-col items-center gap-1">
                <PhoneCallIcon
                  absoluteStrokeWidth={true}
                  height={25}
                  stroke="#6d6d6d"
                  overlineThickness={0.1}
                />
                <div className={` mobile_thin_small`}>Call us</div>
              </div>
            </a>
          </NavigationMenuItem>
        </NavigationMenu>
      </div>
      <SheetContent className="p-4 pt-[80px] flex flex-col justify-between w-full">
        <div className=" z-10">
          <SheetHeader>
            {!session ? (
              <div className=" flex items-center justify-between">
                <SheetTitle className=" mobile_large pb-[20px] flex justify-start items-center pl-4">
                  Welcome
                </SheetTitle>
              </div>
            ) : (
              <div className=" flex flex-col justify-start items-start">
                <div className=" mobile_semibold text-lg text-black">
                  {/* Hello, {session?.user.firstName + ' ' + session?.user.lastName} */}
                  Hello {session?.user.firstName},
                </div>
                <div className=" body_small text-neutral-800 mb-2">{session?.user.phoneNumber}</div>
              </div>
            )}
          </SheetHeader>
          <hr />
          <div className="flex flex-col gap-1 py-2">
            <div
              onClick={() => handleNavigation('/orders')}
              className=" hover:bg-primary-50 p-2 rounded-sm hover:text-primary-500"
            >
              Orders
            </div>
            {/* <div onClick={() => handleNavigation('/saved-addresses')}
              className=" hover:bg-primary-50 p-2 rounded-sm hover:text-primary-500"
            >
              Saved Adresses
            </div> */}
            <div
              onClick={() => handleNavigation('/contactus')}
              className=" hover:bg-primary-50 p-2 rounded-sm hover:text-primary-500"
            >
              Contact Us
            </div>
          </div>
        </div>
        <SheetFooter>
          <SheetClose asChild>
            <div className="">
              {session ? (
                <>
                  <hr />
                  <div className="flex flex-col gap-1 py-2">
                    {/* <Link
                    href={'/edit-profile'}
                    className=" hover:bg-primary-50 p-2 rounded-sm hover:text-primary-500"
                  >
                    Edit Profile
                  </Link> */}
                    <div
                      onClick={handleSignout}
                      className=" my-2 hover:bg-primary-50 p-2 rounded-sm hover:text-primary-500"
                    >
                      Logout
                    </div>
                  </div>
                </>
              ) : (
                <div className="">
                  <hr />
                  <div className=" flex justify-between items-center">
                    <div className=" flex md:hidden mobile_thin_medium py-4 text-neutral-700 w-1/2">
                      To access account and manage orders
                    </div>
                    <Button
                      variant="outline"
                      type="submit"
                      className="my-2 focus-visible:ring-0 ring-0 text-primary-500 mobile_thin_medium md:label_medium hover:text-primary-500 hover:bg-primary-100"
                      // onClick={handleLoginBtn}
                    >
                      LOGIN<div className=" flex md:hidden">&nbsp;/&nbsp;SIGNUP</div>
                    </Button>
                  </div>
                  <hr />
                </div>
              )}
              <hr />
              <div className=" w-full flex justify-center items-center">
                <Button
                  variant="outline"
                  type="submit"
                  className="my-4 w-2/3 text-primary-500 mobile_thin_medium md:label_medium hover:text-primary-500 hover:bg-primary-100"
                  // onClick={handleLoginBtn}
                >
                  Continue Shopping
                </Button>
              </div>
            </div>
          </SheetClose>
        </SheetFooter>
      </SheetContent>
    </Sheet>
  )
}

export default NavDock
