"use client"
import WhatsappIcon from '@assets/icons/whatsapp-social-media-svgrepo-com.svg'
import { cn } from '@lib/utils'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import Image from 'next/image'
import { ComponentProps, FC, useMemo } from 'react'

const NeedHelpProducts: FC<ComponentProps<'div'>> = ({ ...props }) => {
  const path = usePathname()
  
  if (!path.startsWith("/products/")) return null


  const whatsappMessage = `Hello, I’d like to learn more about your offerings. Can you assist me? - https://eventshoppe.in${path}. `

  return (
    <div
      {...props}
      aria-hidden="true"
      role="presentation"
      className={cn(
        ' h-8 md:h-12 bg-[#F13F63] flex w-full justify-center items-center mobile_condensed_small md:label_semibold text-white gap-2 overflow-y-hidden',
        props.className
      )}
    >
      <Link
        href={`https://wa.me/918123400066?text=${whatsappMessage}`}
        className="w-full flex justify-center gap-2"
      >
        <Image src={WhatsappIcon} alt="whatsapp" width={15} height={15} />
        <span className="sr-only">
        Looking for something specific? Let us help
      </span>
      Looking for something specific? Let us help
      </Link>
    </div>
  )
}

export default NeedHelpProducts
