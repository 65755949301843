'use client'
import React, { Children, FC, ReactNode } from 'react'
import {
  QueryClient,
  QueryClientProvider
} from 'react-query'
// const queryClient = new QueryClient()
const QueryClientProviderCom: FC<{ children: ReactNode }> = ({ children }) => {
  const [queryClient] = React.useState(() => new QueryClient())

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryClientProviderCom
