import(/* webpackMode: "eager" */ "/vercel/path0/assets/icons/whatsapp-social-media-svgrepo-com.svg");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/FacebookScripts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/FooterFragment.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/LetUsHelpYouContactnumberForm.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/atoms/NeedHelpProducts.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/context/userContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/Navbar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/molecules/NavDock.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/templates/QueryClientProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/wrappers/LazySection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/hooks/useAuth.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/nextjs-toploader/dist/index.js");
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css")