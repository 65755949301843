'use client';

import React, { useEffect, useState } from 'react';
import Script from 'next/script';

const FacebookScripts = () => {
    const [isClient, setIsClient] = useState(false);

    useEffect(() => {
        setIsClient(true); // Ensure client-side rendering only
    }, []);

    if (!isClient) {
        return null; // Prevent rendering on the server
    }

    return (
        <>
            <Script strategy="lazyOnload" id="-script">
                {`!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '429999753214196');
        fbq('track', 'PageView');`}
            </Script>
            <noscript>{`<img height="1" width="1" style="display:none"
src="https://www.facebook.com/tr?id=429999753214196&ev=PageView&noscript=1"
/>`}</noscript>
            <Script id="facebook-pixel" strategy="afterInteractive">
                {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '429999753214196');
          fbq('track', 'PageView');
        `}
            </Script>
        </>
    );
};

export default FacebookScripts;
