'use client'
import { Button, ButtonProps } from '@components/ui/button'
import { FC } from 'react'

interface ShareBtnProps extends ButtonProps {
  url: string
  title: string
  text: string
}

const ShareButton: FC<ShareBtnProps> = ({ url, title, text, children, ...props }) => {
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: text,
          url: url
        })
        console.log('Content shared successfully')
      } catch (error) {
        // console.error('Error sharing content:', error)
      }
    } else {
      console.log('Web Share API not supported.')
    }
  }

  return (
    <Button onClick={handleShare} className="share-button" {...props}>
      {children}
    </Button>
  )
}

export default ShareButton
