'use client';
import React, { useEffect, useState, ReactNode } from 'react';

interface LazySectionProps {
    children: ReactNode;
    id: string;
}

const LazySection: React.FC<LazySectionProps> = ({ children, id }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect(); // Stop observing once visible
                }
            },
            { threshold: 0.1 } // Adjust threshold as needed
        );

        const element = document.getElementById(id);
        if (element) observer.observe(element);

        return () => observer.disconnect();
    }, [id]);

    return <div id={id}>{isVisible ? children : null}</div>;
};

export default LazySection;
