'use client'
import { sendEnquiryEmail } from '@app/actions'
import LocationIcon from '@assets/footer/telephone.svg'
import { Button } from '@components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@components/ui/form'
import { Input } from '@components/ui/input'
import { zodResolver } from '@hookform/resolvers/zod'
import Image from 'next/image'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as z from 'zod'

const formScheme = z.object({
  phoneNum: z
    .string({ required_error: 'Please enter your mobile number' })
    .regex(/^\d{10}$/, 'Enter a valid phone number')
})

const LetUsHelpYouContactnumberForm = () => {
  const pathname = usePathname()
  const [hideBar, setHideBar] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  useEffect(() => {
    if (pathname == '/shopping-cart/cart') {
      setHideBar(true)
    } else {
      setHideBar(false)
    }
  }, [pathname])

  const form = useForm<z.infer<typeof formScheme>>({
    resolver: zodResolver(formScheme)
  })

  const onSubmit = async (data: z.infer<typeof formScheme>) => {
    await sendEnquiryEmail(data.phoneNum).then(() => {
      setIsSubmitted(true)
    })
  }

  return (
    <>
      {hideBar ? (
        <></>
      ) : (
        <div className="w-full bg-surface-50 flex md:flex-row flex-col items-center justify-center gap-4 md:gap-32 py-4 md:py-10">
          {isSubmitted ? (
            <div>
              <div className=" mobile_semibold md:font-bold md:text-2xl text-primary-500 px-6 text-center">
                Thank you for contacting. Our representative will reach out to you soon!
              </div>
            </div>
          ) : (
            <>
              <div className="flex gap-5 justify-center  items-center p-2 px-4">
                <div className="w-[48px] h-[48px]">
                  <Image src={LocationIcon} alt="let us help you" className="flex-1" />
                </div>
                <div>
                  <div className=" mobile_semibold md:font-bold md:text-2xl text-primary-500">
                    Want a stress-free event planning experience?
                  </div>
                  <div className=" mobile_thin_small md:display_small text-neutral-750">
                    Share your number, and we can help!
                  </div>
                </div>
              </div>
              <Form {...form}>
                <form onSubmit={form.handleSubmit(onSubmit)} className="flex gap-5">
                  <FormField
                    control={form.control}
                    name="phoneNum"
                    render={({ field }) => (
                      <FormItem className="flex flex-col ">
                        <FormControl>
                          <div className="bg-white rounded-sm">
                            <Input
                              className="md:w-96 flex flex-1 p-3 bg-transparent border-none rounded-sm"
                              type="number"
                              {...field}
                            />
                          </div>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <Button type="submit" className=" title_medium text-white">
                    Submit
                  </Button>
                </form>
              </Form>
            </>
          )}
        </div>
      )}
    </>
  )
}

export default LetUsHelpYouContactnumberForm
