// supportStore.ts
import {create} from 'zustand';

interface SupportState {
  activeCard: number;
  setActiveCard: (newActiveCard: number) => void;
}

export const useSupportStore = create<SupportState>((set) => ({
  activeCard: 0,
  setActiveCard: (newActiveCard) => set({ activeCard: newActiveCard })
}));
