'use client'
import { SessionProvider } from 'next-auth/react'
import { Session } from 'next-auth/types'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

export type SignUpObject = {
  firstName: string
  lastName: string
  emailId: string
  phoneNumber: string
}

type AuthState = {
  userId: string
  phoneNumber: string
  isSigningUp: boolean
  tempUserObject: SignUpObject
}

type AuthAction = {
  setUserId: (userId: AuthState['userId']) => void
  setTempUserObject: (obj: AuthState['tempUserObject']) => void
  setSigningUp: (val: AuthState['isSigningUp']) => void
  setPhoneNumber: (number: AuthState['phoneNumber']) => void
}

export const useAuthStore = create<AuthState & AuthAction>()(
  devtools(
    persist(
      (set) => ({
        userId: 'NaN',
        phoneNumber: 'NaN',
        isSigningUp: false,
        tempUserObject: {
          firstName: 'Test',
          lastName: 'User',
          emailId: 'testID@gmail.com',
          phoneNumber: '6238200000'
        },
        setUserId: (userId) => set(() => ({ userId: userId })),
        setPhoneNumber: (number) => set(() => ({ phoneNumber: number })),
        setTempUserObject: (obj: SignUpObject) => {
          set((state) => ({ tempUserObject: { ...state.tempUserObject, ...obj } }))
        },
        setSigningUp: (val: boolean) => set(() => ({ isSigningUp: val }))
      }),
      {
        name: 'auth-storage',
        storage: createJSONStorage(() => sessionStorage)
      }
    )
  )
)

// if (process.env.NODE_ENV === 'development') {
//   mountStoreDevtool('AuthStore', useAuthStore);
// }

export const AuthProvider = ({
  children,
  session
}: React.PropsWithChildren<{ session: Session }>) => {
  return <SessionProvider session={session}>{children}</SessionProvider>
}
