'use client'
import React from 'react'
export interface FooterFragmentProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string
  list: string[]
  linkList: string[]
}
import { cn } from '@lib/utils'
import { useRouter } from 'next/navigation'
import { useSupportStore } from '@app/support/supportStore'

const FooterFragment: React.FC<FooterFragmentProps> = ({ title, list, linkList, ...props }) => {
  const activeCard = useSupportStore((state) => state.activeCard);

  const router = useRouter()
  return (
    <div className={cn('flex flex-col items-start gap-3 px-2 m-2', props.className)} >
      <div className=" mobile_semibold md:heading_small md:pb-6 text-neutral-800">{title}</div>
      {list?list.map((item, index) => (
        <button key={index} className=" mobile_thin_small md:label_medium text-neutral-800 whitespace-nowrap" onClick={()=>router.push(linkList[index])}>
          {item}
        </button>
      )):null}
    </div>
  )
}

export default FooterFragment

