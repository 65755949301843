'use client'
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuTrigger
} from '@/components/ui/navigation-menu'
import CartIcon from '@assets/navIcons/cart.svg'
import ProfileIcon from '@assets/navIcons/profile.svg'
import WishlistIcon from '@assets/navIcons/wishlist.svg'
import { userContext } from '@components/context/userContext'
import { Loader2 } from 'lucide-react'
import { signOut, useSession } from 'next-auth/react'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useContext } from 'react'
import NavbarProfileUserSection from './NavbarProfileUserSection'
import useCartStore from '@store/CartStore'

interface NavMenuProps {}

const NavMenu: FC<NavMenuProps> = () => {
  const { data: session } = useSession()
  const cartContext = useCartStore()
  const user = useContext(userContext)
  const { wishlist, wishlistStatus } = useContext(userContext)

  const handleSignout = () => {
    // TODO: check here
    if (user?.setAddresses) {
      user.setAddresses({ method: 'clear' })
    }
    cartContext?.updateCart({ method: 'clear' })
    signOut()
  }

  return (
    <NavigationMenu aria-hidden="true" className=" list-none gap-5 text-neutral-900 py-2">
      <NavigationMenuItem className=" hidden md:flex h-full ">
        {/* <Link href={session ? '/shopping-cart/cart' : '/signin'}> */}
        <Link href={'/wishlist'}>
          <div aria-hidden="true" className="flex flex-col items-center gap-1 ">
            <div className="relative">
              <Image src={WishlistIcon} alt="access your wishlist" height={20} />

              {session && wishlist?.length !== 0 ? (
                <div className="absolute text-white right-[-10px] top-[-10px] bg-primary-400 w-[20px] flex justify-center items-center opacity-90 rounded-full h-[20px] text-sm aspect-square ">
                  {wishlistStatus === 'LOADING' ? (
                    <span className="animate-spin">
                      <Loader2 />
                    </span>
                  ) : (
                    wishlist?.length
                  )}
                </div>
              ) : null}
            </div>
            <div className="label_medium hidden md:flex">Wishlist</div>
          </div>
        </Link>
      </NavigationMenuItem>

      <NavigationMenuItem>
        {/* <Link href={session ? '/shopping-cart/cart' : '/signin'}> */}
        <Link href={'/shopping-cart/cart'}>
          <div aria-hidden="true" className="flex flex-col items-center justify-center gap-1  h-full">
            <div className="relative">
              <Image src={CartIcon} alt="access your cart" height={20} />
              {cartContext?.cartItems.length !== 0 && (
                <div className="absolute text-white right-[-10px] top-[-8px] bg-primary-400 w-[20px] flex justify-center items-center opacity-90 rounded-full h-[20px] text-sm aspect-square ">
                  {cartContext?.cartItems.length}
                </div>
              )}
            </div>
            <div className="label_medium hidden md:flex">Cart</div>
          </div>
        </Link>
      </NavigationMenuItem>

      <NavigationMenuItem className="hidden md:flex">
        <NavigationMenuTrigger className=" px-0">
          <div
            aria-hidden="true"
            className="flex flex-col items-center gap-1 "
            onClick={() => {
              if (session) {
                console.log('signed in')
              } else {
                // router.push('/signin')
                console.log('not signed in')
              }
            }}
          >
            <Image src={ProfileIcon} alt="access your profile" height={20} />
            <div className="label_medium">Profile</div>
          </div>
        </NavigationMenuTrigger>
        <NavigationMenuContent className="p-4">
          <div aria-hidden="true" className="  md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
            <NavbarProfileUserSection />
            <hr />
          </div>
          <div className="flex flex-col gap-1 py-2">
            <Link href={'/orders'} className=" hover:bg-primary-50 p-1 hover:text-primary-500">
              My Orders
            </Link>
            {/* <Link
              href={'/saved-addresses'}
              className=" hover:bg-primary-50 p-1 hover:text-primary-500"
            >
              Saved Adresses
            </Link> */}
            <Link href={'/contactus'} className=" hover:bg-primary-50 p-1 hover:text-primary-500">
              Contact Us
            </Link>
          </div>

          {session ? (
            <>
              <hr />
              <div className="flex flex-col gap-1 py-2">
                {/* <Link
                  href={'/edit-profile'}
                  className=" hover:bg-primary-50 p-1 hover:text-primary-500"
                >
                  Edit Profile
                </Link> */}
                <div
                  onClick={handleSignout}
                  className=" hover:bg-primary-50 p-1 hover:text-primary-500"
                >
                  Logout
                </div>
              </div>
            </>
          ) : null}
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenu>
  )
}

export default NavMenu
